/* global Component */
class catComponent extends Component {

    static name() {
        return "catComponent";
    }

    static componentName() {
        return "catComponent";
    }

    getProps() {
        return  ['cat',"lvl"];
    }

    getComputed() {
        return {
            isPath:function () {
                let catList = this.$store.state.cat_history;
                return catList.map(x=>x.code).indexOf(this.cat.code) !== -1;
            },
        };
    }

    getMethods() {
        return {
            getName:this.getName,
            filterByCategory:this.filterByCategory
        };
    }

    getName(cat) {
        try{
            return cat.name;
        }
        catch(err){
            return 'Category without Name';
        }
    }

    filterByCategory(cat,lvl){
        window.scrollTo(0, 0);
        if(this.$store.state.cat_selected && cat.code == this.$store.state.cat_selected.code)
            return;
        let catRoutePath = (`${cat.code}__${cat.name}`);
        window.document.title = this.capitalize(cat.name);
        this.$router.push({
            name: 'CategorySEO',
            params: {category: catRoutePath},
            query: {category: catRoutePath}
        });
    }

    getTemplate() {
        return `<div :key="'container-'+getName(cat)" v-bind:class="{'child-categories':lvl>0, 'selected': isPath && cat.children.length == 0}"  >
                  <template v-if="cat.children.length>0" >
                      <template v-if="isPath">
                          <details open>
                              <summary>
                                    <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;">{{getName(cat)}}</span>
                                    <span v-else>{{capitalize(getName(cat))}}</span>
                              </summary>
                              <template v-for="subcat2 in cat.children">
                                  <catComponent :cat="subcat2" :lvl="parseInt(lvl)+1" ></catComponent>
                              </template>
                           </details>
                       </template>
                       <template v-else>
                          <details>
                              <summary>
                                    <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;">{{getName(cat)}}</span>
                                    <span v-else>{{capitalize(getName(cat))}}</span>
                              </summary>
                              <template v-for="subcat2 in cat.children">
                                  <catComponent :cat="subcat2" :lvl="parseInt(lvl)+1" ></catComponent>
                              </template>
                           </details>
                       </template>
                   </template>
                   <span v-else  v-on:click="filterByCategory(cat,lvl)">
                       <template v-if="isPath">
                            <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;"><strong>{{getName(cat)}}</strong></span>
                            <span v-else><strong>{{capitalize(getName(cat))}}</strong></span>
                        </template>
                       <template v-else>
                            <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;">{{getName(cat)}}</span>
                            <span v-else>{{capitalize(getName(cat))}}</span>
                        </template>
                   </span>
              </div>`;
    }
}

catComponent.registerComponent();